   <template>
   <div class="paper" style="left: 0;width: 100%;padding: 0!important;">
      <PaperHeader/>
      <div class="smart-wrap dash" style="align-items: flex-start;padding: 0 20px;" v-if=" organizationData.app == 'DLV'" >
         <div class="col-md-12 options" style="padding: 20px 0;" >
            <h1>{{ data.PRO.attributes['name'].attributeValue}}</h1>
            <p >Phone: {{ data.PRO.attributes['phone'].attributeValue }}</p>
            <ui-accion-button action="refresh" v-on:click="getList()"/>
         </div>
         <div class="col-md-12" style=" display: flex; align-items: center;">
            <div class="input-wrap col-md-6" >
               <label for="">Search</label>
               <input type="text" v-model="searchContact" v-on:keyup.enter="getDataContacts()" >
               <i class="bx bx-search clearSearch"   v-on:click="getDataContacts()"></i>
               <div class="collapse" id="contactList">
                  <div style="padding: 10px;" class="card card-body" v-if="listContacts.length != 0">
                     <a class="smart-wrap" style="display: flex; align-items: center;" v-for="(contact, index) in listContacts" :key="index" v-on:click="setgRecord([contact, 'new-dlv', 'new-pharmacy', data.PRO]);" data-toggle="modal" data-target="#modal-dlv-new">
                        <div style="width: calc(100% - 50px);">
                           <strong style="width: 100%; display: block;"> 
                              {{ 
                                 contact.attributes['sml.contactFullName'].attributeValue['contact.firstName'] 
                                 +' '+
                                 contact.attributes['sml.contactFullName'].attributeValue['contact.lastName']  
                              }}
                           </strong>
                           <span style="font-size: 12px; line-height: 10px; display: block;">
                              {{ contact.attributes['contact.streetAddress'].attributeValue }}
                              {{contact.attributes['contact.city'].attributeValue}}
                              {{contact.attributes['contact.state'].attributeValue}}
                              {{ contact.attributes['contact.zipCode'].attributeValue }}
                           </span>
                           <span  style="font-size: 12px; line-height: 20px; display: block;" v-if="contact.attributes['SMLPhones'].attributeValue[0] && contact.attributes['SMLEmails'].attributeValue[0]" > 
                              <span  >{{ formatData( 'phone', contact.attributes['SMLPhones'].attributeValue[0].phoneNumber) }}</span> &nbsp;
                              <span >{{ contact.attributes['SMLEmails'].attributeValue[0]['contact.email']}}</span>
                           </span>
                        </div>
                        <div class="options" style="width: 40px;">
                           <ui-accion-button action="new" />
                        </div>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-md-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
               <li class="nav-item" v-for="(list, index) in data.Tables" :key="index">
                  <a :class="{'nav-link':true, 'active': index == 0}" v-on:click="list.subscribe = false" :id="list.Title+'-tab'" data-toggle="tab" :href="'#'+list.Title" role="tab" :aria-controls="list.Title" aria-selected="true">
                     <i v-if="list.subscribe " class='bx bxs-star' style='color:#0085ff'  ></i>
                     {{ list.Title }}
                  </a>
               </li>
            </ul>
            <div class="tab-content" id="myTabContent">
               <div v-for="(list, index) in data.Tables" :key="index" class="tab-pane fade show active" style="padding:10px 0;" :id="list.Title" role="tabpanel" :aria-labelledby="list.Title+'-tab'">
                  <div class="list-group"   style="width: 100%;" >
                     <div class="input-wrap" v-if="false">
                        <label for="">Filter</label>
                        <select name="" id="" v-on:change="getList('')" v-model="filter">
                           <option :value="filter" v-for="(filter, findex) in filterList" :key="findex">
                              {{ filter }}
                           </option>
                        </select>
                     </div>
                     <div href="#" class="list-group-item list-group-item-action " v-for="(record, rIndex) in list.data" :key="rIndex"  >
                        <div class="smart-wrap col-md-12"  style="justify-content: space-between;    flex-wrap: wrap;">
                           <span v-if="record.subscribe">
                              <i v-if="record.subscribe == 'edit'" class='bx bxs-pencil' style='color:#5ad911'></i>
                              <i v-if="record.subscribe == 'new'" class='bx bxs-star' style='color:#0085ff'  ></i>
                           </span>
                           <div class="col-md-12 " style="display: flex;align-items: center;justify-content: space-between;background: #e1e1e1;padding: 10px;"> 
                              <span > 
                                 # <strong>{{ record.friendlyId }} </strong> - <span >{{ record.attributes['orderNumber'].attributeValue }} </span> <br>
                                 <strong>Driver: </strong> {{ record.attributes['responsible'].attributeValue.value }} <br>
                                 <span style="font-style: italic;"> {{ formatData('date',record.attributes['date'].attributeValue)[0]}}</span>
                              </span>
                              <img style="width: 80px; border-radius: 100px;" :src="record.attributes['responsible'].picture" alt="">
                           </div>
                           
                           <p class="col-md-12 col-text" > 
                              <span > {{ formatData('date',record.attributes['date'].attributeValue)[0]}}</span>
                           </p>
                           <p class="col-md-12 col-text" > 
                              <strong>Client: </strong> 
                              <span > {{ record.attributes['sml.contactFullName'].attributeValue['contact.salutation'] +' '+record.attributes['sml.contactFullName'].attributeValue['contact.firstName']+' '+record.attributes['sml.contactFullName'].attributeValue['contact.lastName']}} </span>
                           </p>
                           <p class="col-md-12 col-text" v-if="record.attributes['SMLPhones'].attributeValue[0]" > 
                              <span >{{ formatData( 'phone', record.attributes['SMLPhones'].attributeValue[0].phoneNumber) }}</span>
                           </p>
                           <p class="col-md-12 col-text" v-if="record.attributes['SMLEmails'].attributeValue[0]" > 
                              <span >{{ record.attributes['SMLEmails'].attributeValue[0]['contact.email']}}</span>
                           </p>
                           <p class="col-md-12 col-text" > 
                              <span >{{ record.attributes['contact.streetAddress'].attributeValue }}</span>
                              <span >&nbsp;{{ record.attributes['contact.city'].attributeValue }}</span>
                              <span >&nbsp;{{ record.attributes['contact.state'].attributeValue }}</span>
                              <span >&nbsp;{{ record.attributes['contact.zipCode'].attributeValue }}</span>
                           </p>
                           <p class="col-md-12 col-text" > 
                              <strong>{{ record.attributes['provider'].label }}: </strong> 
                              <span > {{ record.attributes['provider'].attributeValue.value }} </span>
                           </p>
                           <p class="col-md-12 col-text" > 
                              <strong>{{ record.attributes['status'].label }}: </strong> 
                              <span > {{ record.attributes['status'].attributeValue }} </span>
                           </p>
                           <DisplayProducts :list="record.attributes.products.attributeValue" />
                           <div class="list-group col-md-12" v-if="record.attributes['pictures'].attributeValue.length != 0">
                              <div class="col-md-12">
                                 <img :src="img.url" style="width: 25%;padding:5px;" alt="" v-for="(img, index) in record.attributes['pictures'].attributeValue" :key="index">
                              </div>
                           </div>
                           <div class="list-group-item list-group-item-action" v-on:click="setgRecord([record, 'out', 'driver']);"  data-toggle="modal" data-target="#modal-file">
                              Add Picture
                           </div>
                           <p class="col-md-12 col-text" > 
                              <strong>{{ record.attributes['notes'].label }}: </strong> 
                              <span > {{ record.attributes['notes'].attributeValue }} </span>
                           </p>
                           <div class="list-group col-md-12">
                              <div class="list-group-item list-group-item-action" v-on:click="setgRecord([record, 'out']);"  data-toggle="modal" data-target="#modal-notes">
                                 Add Notes
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <PaperModalCustom/>
   </div>
</template>
<script>
let tokenQuote = null
   //AWS - GRAPHQL
   import { smd_getWebPage, smd_searchContactByName, smd_getPharmacyDash, smd_getRecordObj   } from "../../../graphql/queries";
   import { onNotify, smd_savePaymentFormContact, smd_updateAttributes  } from '../../../graphql/mutations'
   import { onUpdateSmartRecord, onUpdateRecord, onSetItemState, onUpdateAttributes } from '../../../graphql/subscriptions'
   //Tools
   import Vuex from "vuex";
   import { Auth, API,Storage } from "aws-amplify";
   import "amazon-connect-streams";
   import Swal from 'sweetalert2'
   import { loading, popUp, formatData  } from "../../../store/tools";

   //Components
   import PaperDisplayer from "../../../components/papers/PaperDisplayer.vue";
   import PaperLayout from "../../../components/papers/PaperLayout.vue";
   import PaperUser from "../../../components/papers/PaperUser.vue";
   import PaperModalCustom from '../../../components/papers/PaperModalCustom.vue'
   
   import UiTable from "../../../components/ui/UiTable.vue";
   import UiAccionButton from "../../../components/ui/UiAccionButton.vue";

   import DisplayProducts from "../Components/DisplayProducts.vue"
   import PaperHeader from "../Components/PaperHeader.vue"

   export default {
      components: {
         PaperDisplayer,
         PaperLayout,
         UiTable,
         PaperUser,
         UiAccionButton,
         PaperModalCustom,
         DisplayProducts,
         PaperHeader
      },
      data() {
         return {
            //Record
            list:[],
            filterTable:[],
            filterList:[],
            filter:'',
            data: undefined,
            sml:'',
            hostUrl:'',
            webSite: undefined,
            renderWeb: false,
            widthDisplayWebSite:'100%',
            //
            tandc: false,
            saveCard: false,
            
            methodPayment:'',
            //
            searchContact:'',
            listContacts:[]
         };
      },
      created() {
         this.hostUrl = location.origin
         loading('show', 'webPreview')
         this.setUrlHistory('pharmacy')
         if ( this.organizationID == 'Not authorized') {
            this.$router.push({
               path: "/login",
            });
         } else {
            this.getList()
            this.subscribe()
         }
         $('#pepe').modal('show')
      },
      methods: {
         ...Vuex.mapMutations([
            'setWebSite',
            'SetOrganizationID',
            'SetOrganizationData',
            'setPublicOrganization',
            'setPublicOrganizationData',
            'setgRecord',
            'setUrlHistory',
            'setAttUser'
         ]),
         ...Vuex.mapActions([
            'getPublicOrganization'
         ]),
         formatData(format, data){
            return formatData(format, data)
         },
         //Init
         async setUserWeb(){
            Auth.signIn('smd_webuser', '383@JDU45ss#')
            .then((user) => { 
               //Clear Global Variables
               this.SetOrganizationID("Not authorized")
               this.SetOrganizationData("Not authorized")
               this.setPublicOrganization(undefined)
               this.setPublicOrganizationData(undefined)
               //console.clear()
               ///-----
               //this.getPublicOrganization()
               setTimeout(() => {
                  this.getSML()
                  //this.getList()
               }, 1500);
              //this.loading('hidden')
            })
            .catch((err) => console.log(err))
         },
         async getList(filter){
            //console.clear()
            loading('show', 'webPreview')
            let pushData = {};
            let pullData = ''
            try {
               console.log('this.attUser',this.attUser);
               pushData = {
                  PK: this.organizationID,
                  SK: this.attUser['custom:responsibleSK']//'USR#driver2'//this.attUser['custom:GSP1PK1'],
               }
               console.log('pushData Get Layout:', pushData);
               pullData = await API.graphql({
                  query: smd_getPharmacyDash,
                  variables: pushData
               });
               pullData = pullData.data.smd_getPharmacyDash
               pullData = JSON.parse(pullData.data)
               console.log(pullData);
               let data = pullData
               for (let i = 0; i <  data.Tables.length; i++) {
                  for (let j = 0; j < data.Tables[i].data.length; j++) {
                     if (!this.filterList.includes(data.Tables[i].data[j].attributes.status.attributeValue) && data.Tables[i].data[j].attributes.status.attributeValue != 'Unassigned' && data.Tables[i].data[j].attributes.status.attributeValue != '' ) {
                        this.filterList.push(data.Tables[i].data[j].attributes.status.attributeValue);
                     }
                  }
               }
               this.data = data
               this.setAttUser({ ...this.attUser , name: data.PRO.attributes.name.attributeValue , user: this.user })
               loading('hide', 'webPreview')
            } catch (error) {
               console.log(error);
               popUp(error, 'cach', 'err')
            }
            loading('hide', 'webPreview')
         },
         
         //page
         setValue(data){
            let value = data
            try {
               //value = JSON.parse(value).value
            } catch (error) {
               
            }
            return value
         },
         async subscribe() {
            API.graphql({  query: onUpdateSmartRecord, variables: { PK: this.organizationID }  }).subscribe({
               next: (eventData) => {
                  try {
                     let pullData = eventData.value.data.onUpdateSmartRecord;
                     let subRecord =JSON.parse(pullData.data)
                     //Process subRecord
                     //for (const key in subRecord.attributes) {
                        //subRecord[key] = subRecord.attributes[key].attributeValue
                     //}
                     //subRecord = recordToDisplay(subRecord)
                     //-- Sub in table
                     let exist = false
                     console.log(subRecord);
                     if (subRecord.PK == this.organizationID   ) {
                        console.log('** NEW SUB: '+ subRecord.shortEntity ,subRecord);
                        for (let i = 0; i <  this.data.Tables.length; i++) {
                           for (let j = 0; j < this.data.Tables[i].data.length; j++) {
                              if (this.data.Tables[i].data[j].SK == subRecord.SK) {
                                 this.data.Tables[i].data.splice(j, 1)
                                 subRecord.subscribe = 'edit'
                                 exist = true
                                 if ( this.data.Tables[1].status == subRecord.attributes.status.attributeValue &&  this.data.RES.SK == subRecord.attributes.responsible.attributeValue.SK) {
                                    this.data.Tables[1].data.splice(0, 0, subRecord)
                                    this.data.Tables[1].subscribe = true
                                 }
                                 if( this.data.Tables[0].status == subRecord.attributes.status.attributeValue ){
                                    this.data.Tables[0].data.splice(0, 0, subRecord)
                                    this.data.Tables[0].subscribe = true
                                 }
                                 //this.data.Tables[i].data[j] = subRecord
                              }
                           }
                        }
                        if (exist == false) {
                           subRecord.subscribe = 'new'
                           if (subRecord.attributes.provider.attributeValue.SK && this.data.PRO.SK == subRecord.attributes.provider.attributeValue.SK) {    
                              if ( this.data.Tables[0].status == subRecord.attributes.status.attributeValue) {
                                 this.data.Tables[0].data.splice(0, 0, subRecord)
                                 this.data.Tables[0].subscribe = true
                              }
                              else if (this.data.Tables[1].status == subRecord.attributes.status.attributeValue) {
                                 this.data.Tables[1].data.splice(0, 0, subRecord)
                                 this.data.Tables[1].subscribe = true
                              }
                           }
                        }
                     }else{
                        if (subRecord.PK == '#SMARTDASH' && this.userPermissions.includes('Superadmin')) {
                        //console.log('** NEW SUB ADMIN: '+ subRecord.shortEntity ,subRecord);
                        
                        }else{
                        //console.log('no son iguales')
                        }
                     }
                  } catch (error) {
                     console.log(error);
                  }
               }
            });

            API.graphql({  query: onUpdateAttributes  }).subscribe({
               next: (eventData) => {
                  try {
                     let pullData = eventData.value.data.onUpdateAttributes;
                     let subRecord =JSON.parse(pullData.data)
                     let exist = false
                     console.log(subRecord);
                     if (subRecord.PK == this.organizationID   ) {
                        console.log('** NEW SUB: '+ subRecord.shortEntity ,subRecord);
                        for (let i = 0; i <  this.data.Tables.length; i++) {
                           for (let j = 0; j < this.data.Tables[i].data.length; j++) {
                              if (this.data.Tables[i].data[j].SK == subRecord.SK) {
                                 this.data.Tables[i].data.splice(j, 1)
                                 subRecord.subscribe = 'edit'
                                 exist = true
                                 if (subRecord.attributes.responsible.attributeValue.SK && this.data.Tables[1].status == subRecord.attributes.status.attributeValue) {
                                    this.data.Tables[1].data.splice(0, 0, subRecord)
                                    this.data.Tables[1].subscribe = true
                                 }
                                 if( this.data.Tables[0].status == subRecord.attributes.status.attributeValue ){
                                    this.data.Tables[0].data.splice(0, 0, subRecord)
                                    this.data.Tables[0].subscribe = true
                                 }
                                 //this.data.Tables[i].data[j] = subRecord
                              }
                           }
                        }
                        if (exist == false) {
                           subRecord.subscribe = 'new'
                           if (!subRecord.attributes.responsible.attributeValue.SK && this.data.Tables[i].status == subRecord.attributes.status.attributeValue) {
                              this.data.Tables[0].data.splice(0, 0, subRecord)
                              this.data.Tables[0].subscribe = true
                           }
                           else if (this.data.RES.SK == subRecord.attributes.responsible.attributeValue.SK && this.data.Tables[i].status == subRecord.attributes.status.attributeValue) {
                              this.data.Tables[1].data.splice(0, 0, subRecord)
                              this.data.Tables[1].subscribe = true
                           }
                        }
                     }else{
                        if (subRecord.PK == '#SMARTDASH' && this.userPermissions.includes('Superadmin')) {
                        //console.log('** NEW SUB ADMIN: '+ subRecord.shortEntity ,subRecord);
                        
                        }else{
                        //console.log('no son iguales')
                        }
                     }
                  } catch (error) {
                     console.log(error);
                  }
               }
            });

            API.graphql({ query: onSetItemState }).subscribe({
            next: (eventData) => {
               let pullData = eventData.value.data.onSetItemState;
               let subRecord =JSON.parse(pullData.data)
               //Process subRecord
               //for (const key in subRecord.attributes) {
                  //subRecord[key] = subRecord.attributes[key].attributeValue
               //}
               //subRecord = recordToDisplay(subRecord)
               //-- Sub in table
               let exist = false
               console.log(subRecord);
               if (subRecord.PK == this.organizationID   ) {
                  console.log('** NEW SUB: '+ subRecord.shortEntity ,subRecord);
                  for (let i = 0; i <  this.data.Tables.length; i++) {
                     for (let j = 0; j < this.data.Tables[i].data.length; j++) {
                        if (this.data.Tables[i].data[j].SK == subRecord.SK) {
                           subRecord.subscribe = 'edit'
                           //exist = true
                           this.data.Tables[i].data= splice(j, 1)
                        }
                     }
                  }
               }else{
                  if (subRecord.PK == '#SMARTDASH' && this.userPermissions.includes('Superadmin') ) {
                  //console.log('** NEW SUB ADMIN: '+ subRecord.shortEntity ,subRecord);
                  
                  }else{
                  //console.log('no son iguales')
                  }
               }
               setTimeout(() => { this.renderComponent = true }, 100);
            }
            });
            
         },
         //Edit
         async attUpdate(data, condition){
            loading('show', 'getItem')
            let pullData = ''
            let pushData = ''
            let att = []
            console.log(data.attributes.status.attributeValue);
            if (condition == 1) {    
               att =[
                  { 
                     attributeName: "responsible", 
                     attributeType: "L", 
                     attributeValue: [], 
                  },
               ]
            }else{   
               att =[
                  { 
                     attributeName: "responsible", 
                     attributeType: "L", 
                     attributeValue: JSON.stringify({PK: this.organizationID, SK: this.attUser['custom:responsibleSK'], value: this.data.RES.attributes['responsible.name'].attributeValue, id: this.user}), 
                  },
               ]
            }
            pushData={
               PK: data.PK,   
               SK: data.SK ,
               attributes: JSON.stringify(att) ,
            }
            console.log('pushData: ',pushData);
            try {
               pullData = await API.graphql({
                  query: smd_updateAttributes, //smd_updateSmartRecord
                  variables: { 
                     PK: data.PK,
                     SK: data.SK ,
                     attributes: JSON.stringify(att) ,
                  },
               }
            );
            console.log(pullData);
            this.getList()
            } catch (error) {
               console.log(error);
               popUp(error, 'cach', 'err')
            }
            loading('hidde', 'getItem')
         }, 
         //create
         async getDataContacts() { 
            //loading('show')
            console.time()
            //console.log(this.table);
            let config ={
               PK: this.organizationID,
               searchKey: this.searchContact,
            }
            console.log('Data to List Table: ',config);
            try {
            const wo_PullItem = await API.graphql({
               query: smd_searchContactByName,
               variables: config
            });
            let pullData =wo_PullItem.data.smd_searchContactByName
            //Response
            if (pullData.smd_response.statusCode != 200) {
               popUp(pullData.smd_response, 'response', 'err')
               loading('hidde', 'getItem')
               return
            }
            else if (pullData.smd_response.popAlert == true) {
               popUp(pullData.smd_response, 'response', 'scc')
            }

            this.listContacts = JSON.parse(pullData.data)
            
            console.log(this.listContacts);
            $('#contactList').collapse('show')
            console.log('> Data Query: ', pullData );
            
            } catch (error) {
               console.log(error);
               popUp(error, 'cach', 'err')
            }

            loading('hide')
            //console.timeEnd()
         },
      },
      computed: {
         ...Vuex.mapState([
            "organizationID",
            "publicOrganization",
            "attUser",
            "publicOrganizationData",
            "organizationData",
            'user',
            "dataUser",
            "userPermissions",
            'publicOrgPay'
         ]),
      },
      watch: {
         
      },
   };

</script>
