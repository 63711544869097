<template>
   <div class="col-md-12 col-text" v-if="list != 0"> 
    <div class="list-group col-md-12" style="padding: 10px 0;">
        <div class="list-group-item list-group-item-action smart-wrap" style="padding: 0px;" >
          <div class="input-wrap col-8">
              <label for="">Product Description</label>
          </div>
          <div class="input-wrap col-2">
              <label for="">Qty</label>
          </div>
          <div class="input-wrap col-2">
              <label for="">Price</label>
          </div>
        </div>
        <div class="list-group-item list-group-item-action smart-wrap" style="padding: 5px;" v-for="(row, index) in list" :key="index">
          <div class="input-wrap col-8">
              <p >{{ row.product }}</p>
          </div>
          <div class="input-wrap col-2">
              <p >{{ row.qty }}</p>
          </div>
          <div class="input-wrap col-2">
              <p >{{ row.price }}</p>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import Vuex from 'vuex'
export default {
  props:{
    list:{
      type: Array,
      default: () => []
    },
  },
  computed: {
    ...Vuex.mapState(['organizationPalette'])
  },
}
</script>
