<template>
  <div class="header" style="background: #fff; padding: 10px 20px;" >
    <div class="home-bar">
      <a  v-on:click="dash()" ><i class='bx bxs-home'></i><img :src="publicOrganizationData.logoColor[0].url"  alt=""  style="width:150px; overflow:hidden; height: 47px; display: block; float: right;"> </a>
    </div>
    <div class="control-bar row"> 
      <div class="profile">
          <a class="" data-toggle="dropdown"> 
            <i class="bx bx-user"></i>
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="#"><strong>{{ user.replace(organizationID.replace('ORG#','').toLowerCase()+'.','') }}</strong> </a>
            <a class="dropdown-item" href="#">- <strong v-for="(item, index) in userPermissions" :key="index">{{ item }}</strong> - </a>
            <a class="dropdown-item" :href="'/register?smlName=forgotPasswordpm&smlPK=org'" >Change Password</a>
            <a class="dropdown-item" href="#" v-on:click="logOut();">Log Out</a>
          </div>
      </div>
    </div>
  </div>
</template>
<script>

import Vuex from "vuex";

import { Auth, API,Storage } from "aws-amplify";
import Swal from 'sweetalert2'

import { loading, popUp, formatData  } from "../../../store/tools";
export default {
  methods:{
    async logOut() {
      loading('show')
      //this.clear()
      try {
      await Auth.signOut();
      setTimeout(() => {
          console.log("salir");
          this.$router.push({
            path: "/login",
          });
          loading('hide')
      }, 1000);
      } catch (error) {
      //console.log(error);
      loading('hide')
      Swal.fire({
          icon: 'error',
          title: 'Something went wrong!' ,
          text: error,
          footer: 'smd_getMyOrganization'
      })
      }
    },
  },
  computed: {
    ...Vuex.mapState([
      "organizationID",
      "publicOrganization",
      "attUser",
      "publicOrganizationData",
      "organizationData",
      'user',
      "dataUser",
      "userPermissions",
      'publicOrgPay'
    ]),
  },
}
</script>
